@font-face {
  font-family: grotesk; 
  src: url('assets/fonts/HKGrotesk-Regular.woff');
}
@font-face {
  font-family: grotesk-bold; 
  src: url('assets/fonts/HKGrotesk-Black.woff');
}

@font-face {
  font-family: grotesk-light; 
  src: url('assets/fonts/HKGrotesk-Light.woff');
}
                   
* {
  box-sizing: border-box;
}
html, body, #root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  user-select:none;
  font-family: grotesk, sans-serif;
  height: 100%;
  overflow: hidden;
  background: #384675;
  margin: 0;
  padding: 0;
  cursor: url('assets/cursor.png') 39 39, auto;
}
#root {
  position: absolute;
}
button {
  font-family: inherit;
}


